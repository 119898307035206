import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 750.000000 665.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,665.000000) scale(0.100000,-0.100000)">


<path d="M2817 4202 c-207 -417 -377 -762 -377 -765 0 -4 126 -6 281 -5 l281
3 38 95 38 95 180 3 180 2 7 -100 c7 -95 19 -127 29 -77 6 26 34 184 71 392
14 83 35 195 45 250 11 55 26 138 34 185 8 47 37 213 65 369 28 156 51 290 51
297 0 12 -48 14 -273 14 l-273 0 -377 -758z m597 16 l7 -228 -96 0 c-52 0 -95
2 -95 4 0 2 29 75 64 162 34 87 73 190 86 227 12 38 23 67 25 65 1 -2 5 -105
9 -230z"/>
<path d="M3796 4938 c-5 -22 -16 -83 -71 -403 -14 -77 -45 -257 -70 -400 -25
-143 -61 -350 -81 -459 -19 -110 -37 -210 -40 -223 l-6 -23 333 0 c344 0 472
10 597 46 281 81 492 279 578 544 36 113 46 322 19 433 -38 161 -144 310 -278
395 -148 92 -210 103 -634 109 l-342 5 -5 -24z m566 -452 c66 -19 114 -60 144
-122 24 -50 26 -62 22 -151 -3 -81 -9 -107 -31 -149 -51 -100 -159 -167 -288
-181 -58 -6 -59 -5 -59 19 0 14 15 110 34 214 19 104 42 233 51 287 l17 97 33
0 c17 0 52 -6 77 -14z"/>
<path d="M4385 3156 c-83 -39 -125 -103 -125 -191 0 -82 51 -143 154 -185 38
-16 56 -29 54 -39 -7 -37 -89 -24 -153 26 -27 20 -50 31 -54 26 -3 -6 -26 -40
-50 -77 -43 -65 -43 -67 -25 -87 10 -11 52 -36 93 -57 68 -33 83 -37 155 -37
71 0 88 4 136 30 72 40 112 96 118 169 8 101 -23 148 -138 206 -64 32 -80 54
-50 70 23 12 82 -9 116 -41 25 -23 26 -22 75 70 l33 64 -29 23 c-73 58 -220
73 -310 30z"/>
<path d="M4935 3156 c-42 -18 -76 -42 -112 -80 -68 -73 -96 -144 -96 -251 -1
-95 26 -158 92 -218 63 -56 103 -70 206 -71 77 -1 98 3 146 26 75 34 134 95
171 175 26 56 31 77 31 147 1 123 -43 204 -141 262 -41 25 -59 29 -142 31 -83
3 -103 1 -155 -21z m190 -210 c32 -32 40 -69 26 -116 -12 -41 -48 -76 -88 -86
-83 -21 -147 70 -109 155 32 70 123 95 171 47z"/>
<path d="M5690 3171 c-117 -38 -200 -123 -234 -240 -45 -154 11 -302 139 -366
53 -26 71 -29 145 -29 107 0 177 25 241 89 66 65 101 140 107 230 9 140 -36
234 -139 290 -47 25 -66 30 -144 32 -49 1 -101 -1 -115 -6z m153 -229 c21 -22
27 -38 27 -73 -1 -53 -16 -84 -56 -110 -94 -61 -193 46 -139 151 21 41 53 60
100 60 32 0 47 -6 68 -28z"/>
<path d="M783 2898 c-76 -150 -146 -290 -155 -310 l-17 -38 118 0 118 0 12 40
12 40 74 0 75 0 0 -40 0 -40 105 0 c124 0 114 -23 85 195 -11 83 -27 212 -36
288 l-17 137 -118 0 -119 0 -137 -272z m226 -85 c1 -31 -1 -33 -35 -33 -30 0
-35 3 -30 18 4 9 17 43 29 75 l22 58 7 -43 c3 -24 7 -58 7 -75z"/>
<path d="M1365 3158 c-2 -7 -13 -67 -24 -133 -12 -66 -35 -196 -51 -289 -17
-93 -30 -173 -30 -177 0 -5 90 -9 200 -9 110 0 200 1 200 3 0 1 7 40 15 87 8
47 15 88 15 93 0 4 -38 7 -84 7 -47 0 -87 3 -89 8 -2 4 11 88 29 187 18 99 33
192 33 208 l1 27 -105 0 c-76 0 -107 -4 -110 -12z"/>
<path d="M2245 3158 c-5 -14 -97 -324 -146 -493 -16 -55 -29 -104 -29 -108 0
-4 46 -6 102 -5 l102 3 32 120 c18 66 33 128 34 138 0 9 4 17 8 17 5 0 14 -63
22 -140 l13 -141 64 3 65 3 59 135 c33 74 63 137 66 140 6 5 -4 -181 -13 -242
l-6 -38 101 0 c98 0 101 1 102 23 0 12 2 36 4 52 2 17 6 102 9 190 4 88 9 204
13 258 l6 97 -110 0 -110 0 -69 -162 -69 -163 -13 150 c-7 83 -15 156 -17 163
-3 8 -34 12 -110 12 -75 0 -107 -4 -110 -12z"/>
<path d="M2993 2866 c-84 -168 -153 -307 -153 -310 0 -3 51 -6 113 -6 l114 0
16 40 15 40 71 0 70 0 3 -37 3 -38 108 -3 109 -3 -6 28 c-3 15 -15 107 -26
203 -12 96 -28 220 -35 275 -8 55 -15 103 -15 108 0 4 -53 7 -117 7 l-118 0
-152 -304z m237 -10 l0 -74 -30 -4 c-17 -3 -32 -3 -34 -1 -2 3 6 30 18 61 13
31 26 65 29 75 12 33 17 16 17 -57z"/>
<path d="M3600 3163 c0 -4 -12 -69 -25 -143 -14 -74 -37 -200 -50 -279 -14
-79 -28 -154 -31 -167 l-6 -25 113 3 114 3 24 149 24 149 39 -109 c21 -60 45
-128 54 -151 l16 -43 108 0 108 0 11 48 c5 27 15 78 20 113 6 35 27 150 46
254 19 105 35 193 35 198 0 10 -208 9 -214 -2 -3 -5 -17 -71 -31 -147 -14 -76
-27 -139 -29 -141 -1 -2 -25 64 -52 145 l-50 147 -112 3 c-62 1 -112 -1 -112
-5z"/>
<path d="M6237 3163 c-7 -11 -107 -566 -107 -593 0 -19 6 -20 104 -20 l104 0
18 99 c19 101 26 114 38 64 4 -16 16 -58 27 -95 l20 -68 109 0 c62 0 110 4
110 9 0 5 -18 57 -39 116 l-39 106 38 19 c52 27 86 71 98 127 21 100 -14 175
-100 214 -47 20 -72 24 -214 27 -89 2 -164 0 -167 -5z m236 -162 c57 -22 37
-94 -28 -107 -20 -3 -38 -4 -40 -2 -3 3 0 30 6 61 12 59 19 64 62 48z"/>
<path d="M1765 2828 c-3 -18 -10 -55 -15 -83 -5 -27 -5 -53 -1 -58 4 -4 75 -6
157 -5 l149 3 12 70 c20 116 35 105 -142 105 l-154 0 -6 -32z"/>
<path d="M1633 2339 c-187 -72 -243 -317 -109 -476 15 -17 54 -46 88 -62 l61
-31 2077 0 2077 0 61 31 c106 52 162 144 162 264 0 115 -60 212 -165 269 l-40
21 -2080 2 c-2056 2 -2081 2 -2132 -18z m844 -189 c25 -63 58 -150 74 -193
l29 -78 -42 3 c-37 3 -42 6 -53 38 l-12 35 -71 3 -71 3 -12 -41 c-11 -38 -14
-40 -51 -40 -21 0 -38 4 -38 10 0 7 78 221 129 353 9 24 16 28 42 25 31 -3 34
-7 76 -118z m553 100 c11 -21 140 -357 140 -365 0 -3 -17 -5 -38 -5 -36 0 -40
3 -52 40 l-13 40 -69 0 -70 0 -15 -40 c-15 -37 -19 -40 -56 -40 -27 0 -38 4
-35 13 3 6 35 94 73 195 66 177 68 182 96 182 18 0 32 -7 39 -20z m401 9 c33
-10 38 -16 44 -51 4 -22 4 -42 1 -45 -3 -3 -21 2 -40 11 -98 46 -178 -20 -161
-132 12 -84 77 -120 150 -84 24 11 46 18 49 15 3 -4 6 -22 6 -40 0 -30 -5 -36
-37 -50 -54 -22 -132 -13 -176 20 -94 71 -102 235 -16 314 56 51 108 63 180
42z m375 -19 c53 -33 84 -95 84 -168 0 -72 -12 -107 -48 -146 -105 -112 -293
-48 -316 109 -26 168 142 290 280 205z m1445 20 c29 -8 38 -16 41 -38 2 -15 2
-35 0 -44 -3 -15 -6 -15 -37 -2 -63 26 -123 12 -144 -32 -34 -74 -16 -161 39
-189 39 -20 58 -19 103 5 41 22 49 17 43 -33 -4 -41 -17 -49 -88 -54 -50 -3
-70 0 -98 15 -114 62 -133 237 -36 331 49 47 106 61 177 41z m-3121 -23 c112
-59 123 -254 19 -328 -29 -21 -48 -24 -131 -27 l-98 -4 0 191 0 191 83 0 c68
0 92 -4 127 -23z m696 8 c4 -8 3 -26 0 -40 -6 -22 -11 -25 -44 -23 l-37 3 -3
-152 -3 -153 -39 0 -40 0 0 149 0 150 -37 3 c-36 3 -38 5 -41 41 l-3 37 121 0
c98 0 122 -3 126 -15z m1289 -106 l80 -121 3 121 3 121 39 0 40 0 0 -190 0
-190 -42 0 -43 0 -75 115 c-41 63 -78 115 -82 115 -5 0 -8 -52 -8 -116 l0
-115 -37 3 -38 3 -3 175 c-1 96 0 180 2 187 3 8 21 13 43 13 37 -1 39 -3 118
-121z m390 6 c42 -63 81 -117 86 -120 5 -4 9 43 9 114 l0 121 35 0 35 0 0
-190 0 -190 -40 0 -41 0 -77 118 -77 117 -3 -117 -3 -118 -39 0 -40 0 0 190 0
190 39 0 c38 0 40 -1 116 -115z m443 78 c-3 -37 -4 -38 -45 -40 -24 0 -50 -2
-58 -2 -10 -1 -15 -12 -15 -36 l0 -35 60 0 60 0 0 -40 0 -40 -57 2 -58 3 -3
-43 -3 -42 60 0 61 0 0 -35 0 -35 -100 0 -100 0 0 190 0 190 101 0 100 0 -3
-37z m630 3 c4 -34 -10 -46 -55 -46 l-33 0 0 -150 0 -150 -40 0 -40 0 0 149 0
150 -37 3 c-36 3 -38 5 -41 41 l-3 38 123 -3 123 -3 3 -29z"/>
<path d="M2381 2093 c-8 -21 -16 -44 -18 -50 -4 -9 7 -13 37 -13 30 0 41 4 37
13 -2 6 -10 29 -18 50 -7 20 -16 37 -19 37 -3 0 -12 -17 -19 -37z"/>
<path d="M2977 2095 c-22 -65 -22 -65 18 -65 40 0 42 6 16 69 l-18 44 -16 -48z"/>
<path d="M3639 2159 c-25 -27 -29 -39 -29 -90 0 -51 4 -63 29 -91 39 -43 98
-46 138 -6 50 51 40 177 -16 208 -40 21 -91 12 -122 -21z"/>
<path d="M2000 2064 l0 -117 42 6 c56 7 89 42 95 99 6 50 -14 100 -46 118 -11
5 -36 10 -55 10 l-36 0 0 -116z"/>
<path d="M6938 3 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
